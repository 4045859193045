
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from '@/components/dataTables/cell/cell'
  import TreasurerIncomeForm from '@/views/forms/custom/TreasurerIncomeForm.vue'

@Component({
    components: { TreasurerIncomeForm },
  })
  export default class VoidedPaymentCell extends Cell {
    disabledButton = false
    open = false
    // Methods

    // Getters
    get info () {
      return this.item
    }

  // Watchers
  @Watch('item', { immediate: true, deep: true })
  async onItemChange (val) {
    if (!val?.id) return
    const order = (await this.fetchData({
      query: { name: 'find', model: 'SaleOrder' },
      filter: { id_deal: { _eq: val.deal.id } },
    }))[0]

    this.disabledButton = !order?.closingReason?.name || order?.closingReason?.name !== 'closed_deal' || Boolean(val?.closingReason?.id)
  }

    openPayment () {
      this.open = true
    }
  }
